<template>
  <section class="links-with-images-block custom-section">
    <div class="container">
      <h2
        v-if="title"
        class="custom-section__title"
        v-html="title"
      />
      <div class="links-with-images-block__swiper">
        <swiper
          :modules="[Mousewheel, Scrollbar]"
          :scrollbar="true"
          :mousewheel="true"
          :space-between="0"
          :slides-per-view="'auto'"
        >
          <template
            v-for="(item, index) in items"
            :key="`links-with-images-block__list-${index}`"
          >
            <swiper-slide>
              <nuxt-link
                :to="item.link"
                class="links-with-image-card"
              >
                <div class="links-with-image-card__image">
                  <nuxt-img
                    :src="String(item.image)"
                    :alt="item.title"
                    width="200px"
                    height="130px"
                    loading="lazy"
                  />
                </div>
                <div
                  class="links-with-image-card__body"
                  v-html="item.title"
                />
              </nuxt-link>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <div class="links-with-images-block__mobile-message">
        Выберите нужный вам раздел выше
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import { computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Mousewheel, Scrollbar } from 'swiper/modules';

const props = defineProps<IBlockDefaultProps>();

const title = computed(() => props?.values?.title || '');
const items = computed(() => {
  const list = props?.values?.items || [];
  return list;
});
</script>

<style lang="scss">
.links-with-images-block__swiper {
  .swiper {
    margin: 0 calc((100vw - var(--width-container)) / 2 * -1);
    padding: 0 calc((100vw - var(--width-container)) / 2);
  }
  .swiper-slide {
    display: flex;
    width: 197px;
    max-width: 197px;
    min-width: 197px;
    margin-right: 20px;
    height: auto;
  }
  .swiper-scrollbar {
    position: initial;
    margin-top: 32px;
    height: 1px;
    background: #F4F3F1;
  }
  .swiper-scrollbar .swiper-scrollbar-drag {
    background: #B8B8B8;
  }
}
.links-with-images-block__mobile-message {
  display: none;

  margin-top: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  color: #989898;
}

.links-with-image-card {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: #F4F3F1;
  border-radius: 20px;
  height: auto;
  width: 100%;
  border: 1px solid #F4F3F1;
}
.links-with-image-card__image {
  position: relative;
  overflow: hidden;
  border-radius: 0 0 20px 20px;

  img {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:after {
    content: "";
    float: left;
    padding-top: 65.99%;
  }
}
.links-with-image-card__body {
  padding: 24px;
  box-sizing: border-box;

  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}

@media (max-width: 1023px) {
  .links-with-images-block__swiper {
    .swiper {
      margin: 0 calc(((100vw - var(--width-container)) / 2 + 20px) * -1);
      padding: 0 calc(((100vw - var(--width-container)) / 2 + 20px));
    }
  }
  .links-with-images-block__mobile-message {
    display: block;
  }

  .links-with-image-card__body {
    padding: 16px;
  }
}
@media (max-width: 859px) {
  .links-with-images-block__swiper {
    .swiper {
      margin: 0 calc(((100vw - var(--width-container)) / 2 + 10px) * -1);
      padding: 0 calc(((100vw - var(--width-container)) / 2 + 10px));
    }
  }
}
@media (max-width: 619px) {
  .links-with-images-block__swiper {
    .swiper {
      margin: 0 -20px;
      padding: 0 20px;
    }
    .swiper-slide {
      width: 152px;
      max-width: 152px;
      min-width: 152px;
      margin-right: 16px;
    }
  }
}
</style>
